import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCustomMessages } from '@core/errors/models/constants/custom-messages.constants';
import { CErrorModakTypes } from '@core/errors/models/constants/modak-errors.constants';
import { NewErrorBodyModak, NewErrorModak } from '@core/errors/models/interface/error-modak';
import { ILogHttpError } from '@core/logs/models/interface/error.interface';
import { IErrorBodyModak, IErrorModakCodeType } from '@core/errors/models/interface/errors.interface';
import { ErrorPlatform, modakErrorType } from '@core/errors/models/types/errors.types';
import { IErrorEventDataDefinition } from 'src/app/configs/libraries/tracking-manager/interfaces/error.event.data.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ErrorsUtils{
  private readonly SYSTEM_ERROR: string = "System error";

  public httpErrorContainCode(httpError:HttpErrorResponse ,code:string):boolean{
    if(httpError && httpError instanceof HttpErrorResponse) {
      const errorsModak:NewErrorBodyModak[] = this.getModakErrorsByHttpError(httpError)
      if (!errorsModak?.length) {
        return false;
      }
      return errorsModak.some(error => error.code === code)
    }
    return false
  }

  public getModakErrorsByHttpError(httpError:HttpErrorResponse):NewErrorBodyModak[] | null {
    const bodyHttpError = httpError.error
    if (
        bodyHttpError &&
        bodyHttpError instanceof Object &&
        'errors' in bodyHttpError
    ) {
      return bodyHttpError.errors;
    }

    return null;
  }

  public getLastError(errorsModak:NewErrorBodyModak[]): NewErrorBodyModak | null {
    if (errorsModak && errorsModak.length > 0) {
      return errorsModak[errorsModak.length - 1];
    }
    return null
  }

  public transformHttpErrorToDataEventError( httpError: HttpErrorResponse): IErrorEventDataDefinition {
    return {
        code: httpError.status
          ? httpError.status.toString()
          : this.SYSTEM_ERROR,
        message: httpError.message || this.SYSTEM_ERROR,
    };
  }

  public transformModakToDataEventError({errors}: NewErrorModak): IErrorEventDataDefinition {
    const lastError = this.getLastError(errors)

    if (lastError) {
      return {
        code: lastError.code,
        message: lastError.message
      }
    }

    return {
        code: this.SYSTEM_ERROR,
        message: this.SYSTEM_ERROR
    }
  }

  public getHttpError(httpError: HttpErrorResponse): ILogHttpError {
    const requestId = httpError.headers.get('x-amzn-requestid') || '';

    let logError : ILogHttpError = {
      url: httpError.url,
      status: httpError.status,
      code: httpError.statusText,
      message: httpError.message,
      requestId: requestId
    }

    const errorsModak:NewErrorBodyModak[] = this.getModakErrorsByHttpError(httpError)
    const lastError = this.getLastError(errorsModak)
    if (lastError) {
      logError.code = lastError.code,
      logError.message = lastError.message
    }

    return logError
  }
  
  public determinateModakErrorType(error: any): modakErrorType {
    if (error && error instanceof Object) {
      return 'errors' in error
        ? CErrorModakTypes.NEW
          : 'cause' in error
        ? CErrorModakTypes.CAUSE
          : 'code' in error
        ? CErrorModakTypes.CODE
          : CErrorModakTypes.NONE;
    }
    return CErrorModakTypes.NONE;
  }

  //#region [---- TRACKING ERROR HANDLER  ----]

  public getEventDataError(error: ErrorPlatform): IErrorEventDataDefinition | null {
    if(!error) return null

    // IS HTTP ERROR
    if(error instanceof HttpErrorResponse) {
      const modakTypeError:modakErrorType = this.determinateModakErrorType(error?.error)
      switch (modakTypeError) {
        case CErrorModakTypes.NEW:
          return this.getEventNewErrorModak(error?.error)
        case CErrorModakTypes.CAUSE:
          return this.getEventErrorModakWithCause(error?.error)
        case CErrorModakTypes.CODE:
          return this.getEventErrorModakWithCode(error?.error)
        default:
          return {
            code: error?.status?.toString(),
            message: error?.message
          }
      }
    }

    // IS MODAK ERROR
    if(error instanceof NewErrorModak){
      return this.getEventNewErrorModak(error)
    }

    return null

  }

  private getEventNewErrorModak({errors}:NewErrorModak):IErrorEventDataDefinition{
    const {code,message} = errors[errors.length - 1];
    return {code,message}
  }

  private getEventErrorModakWithCause({cause,code}:IErrorBodyModak):IErrorEventDataDefinition {
    const errorMessage = cause ? cause : ErrorCustomMessages.DEFAULT_NOT_5XX
    return {code,message:errorMessage}
  }
  private getEventErrorModakWithCode({code,message}:IErrorModakCodeType):IErrorEventDataDefinition {
    return {code,message}
  }
  //#endregion
}
