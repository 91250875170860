export const C_StatusKYC_2 = {
    KYC_NOT_STARTED: 'not_started',
    KYC_SUBMITTED:   'submitted',
    KYC_STARTED: 'started',
    KYC_APPROVED:    'approved',
    KYC_DECLINED:    'declined',
    KYC_IN_REVIEW:   'in_review',
    KYC_MANUALLY_REVIEW: 'manually_review',
    NONE: 'NONE'
} as const;

export const C_KYC_IN_REVIEW_SUB_STATES = {
    NONE: 'none',
    IDV:     'idv',
    IDV_RESUBMIT:   'idv_resubmit',
    IDV_SUBMITTED:    'idv_submitted',
    IDV_DOCS:    'idv_docs',
    IDV_DOCS_SUBMITTED:   'idv_docs_submitted',
    MANUAL_REVIEW_REQUIRED: 'manual_review_required'
} as const;