import { commonEnvironment } from "src/environments/environment.common";

export const environment = {
  ...commonEnvironment,
  production: false,
  DOMAIN: 'app.dev.modakmakers.com',
  APIS:{
    MONOLITH_URL: 'https://api.dev.modak.live/v1',
    WEBSOCKET_URL: 'wss://ws.testing.dev.modak.live',
    USERS_DOMAIN_URL: 'https://rest.testing.dev.modak.live/users', 
    BANKING_DOMAIN_URL: 'https://rest.testing.dev.modak.live/banking',
    CHORES_DOMAIN_URL: 'https://rest.testing.dev.modak.live/chores',
    KYC_DOMAIN_URL: 'https://rest.testing.dev.modak.live/kyc',
  },

  RISK:{
    SCRIPT_URL:'https://risk.sandbox.checkout.com/cdn/risk/1/risk.js',
    PUBLIC_KEY:'pk_sbox_sxqy25ubth3uerl3varsngj6dmv',
  },

  WEB_VIEW_KEY: 'XxJYYlhM1c03e',
  DISCORD_SERVER_ID: '984837569776853022',

  GOOGLE:{
    ...commonEnvironment.GOOGLE,
    GTM_ID: 'GTM-5HBWSFV',
  },

  //SEGMENT
  SEGMENT_WRITE_KEY: 'BgZtBU0qYDpGRLbXDI5HVFT3tHEHKYEe',

  //SENTRY
  SENTRY: {
    ...commonEnvironment.SENTRY,
    ENVIRONMENT: 'development',
    TRACE_SAMPLE_RATE: 0.1,
    REPLAY_SESSION_SAMPLE_RATE: 0,
    REPLAY_ERROR_SAMPLE_RATE: 0
  },

  // VGS
  VGS:{
    ...commonEnvironment.VGS,
    vaultId: 'tntbevlgikb',
    environment: 'sandbox',
  },

  firebase:{
    ...commonEnvironment.firebase,
    collectionName: 'features-dev'
  },

  // WAF PROTECTION
  WAF: {
    REGION: 'us-west-2',
    IDENTIFIER: '16877655b902',
    TOKEN: '1fcb5f7a3daf', //SANDBOX: 91150df7c6fe
    SKIP_KEY: 'Ex5bCDvlTsAirNTHHo5jvX2hpjoYCNmW',
  },

  Cognito: {
    userPoolId: 'us-west-2_y62WePQL3',
    userPoolClientId: '54pil7kl4rh967rurj8q39bfgb',
  }
};