import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as Sentry from "@sentry/angular-ivy";
import { CSentryPropagationTargets } from "src/app/configs/libraries/sentry/constants/trace-propagation-targets.constants";
import { CSentrySeverityLevels } from "src/app/configs/libraries/sentry/constants/severity-levels.constants";
import { IUser } from "@pages/auth/interfaces/auth.interfaces";
import { ILogData, ILogError, ILogUserNotAuthenticated } from "@core/logs/models/interface/error.interface";
import { AuthUtils } from "src/app/modules/authentication/authentication.utils";
import { CSentryTags } from "src/app/configs/libraries/sentry/constants/tags.constants";
import { CSentryKeys } from "src/app/configs/libraries/sentry/constants/keys.constants";
import { CSentryCurrentUrls } from "src/app/configs/libraries/sentry/constants/current-urls.constants";

@Injectable({
    providedIn: 'root'
})
export class SentryService {
    public log(data: ILogData, user?: ILogUserNotAuthenticated): void {
        if(data.data) Sentry.setExtra(CSentryKeys.DATA, data.data)
        if(user) this.setUserNotAuthenticated(user)
        Sentry.captureMessage(data.message, CSentrySeverityLevels.LOG);
    }

    public error(error: ILogError, user?: ILogUserNotAuthenticated): void {
        Sentry.setTags(null);
        Sentry.setExtras(null);

        if(error.apiError) {
            Sentry.setTag(CSentryTags.URL,error.apiError.url)
            Sentry.setTag(CSentryTags.STATUS,error.apiError.status)
            Sentry.setTag(CSentryTags.CODE,error.apiError.code)
            Sentry.setTag(CSentryTags.MESSAGE,error.apiError.message)
            if(error.apiError.requestId)
                Sentry.setTag(CSentryTags.REQUEST_ID,error.apiError.requestId)
        }

        if(error.additionalTags?.length) {
            error.additionalTags.forEach(tag => {
                Sentry.setTag(tag.name, tag.value)
            })
        }

        if(error.error) Sentry.setExtra(CSentryKeys.ERROR, error.error)
        if(user) this.setUserNotAuthenticated(user)
        Sentry.captureMessage(error.title, CSentrySeverityLevels.ERROR)
    }

    private setUserNotAuthenticated(user: ILogUserNotAuthenticated): void {
        Sentry.setUser({
            id: user.id,
            phone: user.phone,
            email: user.email,
            username: user?.username || user?.phone,
        })
    }

    public setUser(user: IUser): void {
        const tokenUtils = new AuthUtils();

        let tokenUserId = null;
        if (user?.accessToken) {
            const tokenDecoded = tokenUtils.getTokenDecodedUser(user.accessToken);
            tokenUserId = tokenDecoded.user_uuid
        }

        Sentry.setUser({
            id: user?.id ?? tokenUserId,
            role: user?.role,
            parentStatus: user?.parentStatus,
        })
    }
    public clearUser(): void {
        Sentry.setUser(null)
    }

    public init(): void {
        const currentUrl = window.location.href;
        // TODO remove comment before merge main
        const isSubBranch = currentUrl.includes(CSentryCurrentUrls.SUB_BRANCH);
        const isLocalhost = currentUrl.includes(CSentryCurrentUrls.LOCALHOST);


         // TODO remove comment before merge main
        // const canUseSentry = !isLocalhost && environment.SENTRY.DSN;
        const canUseSentry = !isLocalhost && !isSubBranch && environment.SENTRY.DSN;
        //Sentry's Angular SDK captures all unhandled exceptions and transactions.
        if(canUseSentry) {
          Sentry.init({
            //debug: true, 
            environment: environment.SENTRY.ENVIRONMENT,
            dsn: environment.SENTRY.DSN,
            /*
            https://docs.sentry.io/platforms/javascript/guides/angular/best-practices/offline-caching/
            If your JavaScript application is designed to continue working offline, dropping events when no connection is available and missing offline events, could mean you're missing important information.
            To enable offline events caching, use makeBrowserOfflineTransport to wrap existing transports and queue events using the browsers' IndexedDB storage. Once your application comes back online, all events will be sent together.
            */
            transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
            integrations: [
              new Sentry.BrowserTracing(),
              new Sentry.Replay()
            ],
            normalizeDepth: 10,
            //REFACTOR: We need to know what version is using the user
            //RELEASE VERSION
            //release: '',
            //dist: '',
            // Performance Monitoring
            tracePropagationTargets: [
                CSentryPropagationTargets.LOCALHOST,
                CSentryPropagationTargets.ANY, 
                CSentryPropagationTargets.MODAK,
            ],
            tracesSampleRate: environment.SENTRY.TRACE_SAMPLE_RATE, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: environment.SENTRY.REPLAY_SESSION_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: environment.SENTRY.REPLAY_ERROR_SAMPLE_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          });
        }
    }
}