import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
  HttpContext,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, finalize, from, lastValueFrom, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { NO_INSERT_TOKEN, NO_INTERCEPTORS, NO_VERIFY_UNAUTHORIZE } from './constants/interceptors.constants';

import { AuthFunctions } from 'src/app/pages/auth/auth.functions';
import { HttpTranslateUrl } from '../../configs/libraries/ngx-translate/constants/languages.constants';
import { TrackingManager } from 'src/app/configs/libraries/tracking-manager/tracking-manager';
import { AUTH_EVENTS } from '@pages/auth/auth-parent/models/constants/auth-parent.events.constants';
import { PROJECT_TAGS } from 'src/app/configs/libraries/tracking-manager/constants/projects.constants';
import { WafProtectionService } from '@core/services/waf-protection/waf-protection.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private readonly authFunctions: AuthFunctions = inject(AuthFunctions)
  private readonly trackingManager: TrackingManager = inject(TrackingManager)
  private readonly wafProtectionService: WafProtectionService = inject(WafProtectionService)

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if is the HttpTranslate,ignore interceptor
    if (request.url.includes(HttpTranslateUrl)) return next.handle(request)

    // No insert Token
    if(this.useNotInterceptor(request.context)) {
      return from(this.handleRequest(request, next, false));
    }

    if(this.validateUnauthorizedError(request.context)) {
      return from(this.handleRequest(request, next, true))
    }

    return from(this.handleRequest(request, next, true)).pipe(
      catchError((error: HttpErrorResponse) => {
        this.wafProtectionService.resetWafCount();
        if (error.status === HttpStatusCode.Unauthorized) {
          this.trackingManager.trackEventV2(AUTH_EVENTS.USER_UNAUTHORIZED, {
            project_tag: PROJECT_TAGS.APP_ISSUES
          })
          this.authFunctions.logout(false)
        }
        
        return throwError(() => error);
      })
    )
  }

  private async handleRequest(request: HttpRequest<any>, next: HttpHandler, useToken: boolean): Promise<HttpEvent<any>>{

    if(useToken) {
      const accessToken = await this.authFunctions.getToken();
      if(accessToken) {
        request = this.createRequestWithJWT(request, accessToken);
      }
    }

    if (this.wafProtectionService.isModakUrl(request)) {
      const awsToken = await this.wafProtectionService.getToken();
      if (awsToken) {
        request = this.wafProtectionService.createRequestWithAwsToken(request,awsToken);
      }
    }

    let initialEvent: HttpResponse<any> | null = null;

    return lastValueFrom(next.handle(request).pipe(
      switchMap((event) => {
        if (event instanceof HttpResponse) {
            if (this.wafProtectionService.isChallenge(request, event)) {
                this.trackWafChallengeEvent(request, event)
            }
        }
        return of(event);
      }),
      tap((event) => {
        if (event instanceof HttpResponse) {
            initialEvent = event;
        }
      }),
      finalize(() => {
        if (this.wafProtectionService.isModakUrl(request)) {
          if (!initialEvent || initialEvent.status !== HttpStatusCode.Accepted) {
              this.wafProtectionService.resetWafCount();
          }
        }
      })
    ));
  }

  private createRequestWithJWT(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  private useNotInterceptor(ctx: HttpContext): boolean {
    if(ctx.get(NO_INSERT_TOKEN) === true || ctx.get(NO_INTERCEPTORS) === true) {
      return true;
    }

    return false
  }

  private validateUnauthorizedError(ctx: HttpContext): boolean {
    return ctx.get(NO_VERIFY_UNAUTHORIZE) === true
  }

  private trackWafChallengeEvent(request: HttpRequest<any>, event: HttpResponse<any>) {
    this.wafProtectionService.incrementWafCount();
    this.trackingManager.trackEventV2(AUTH_EVENTS.WAF_CHALLENGE, {
        project_tag: PROJECT_TAGS.APP_ISSUES,
        WAF: {
            count: this.wafProtectionService.getCountError(),
            response: {
                status: event.status,
                headers: this.convertHttpHeadersToArray(event.headers)
            },
            request: {
                body: request.body,
                url: request.url,
                headers: this.convertHttpHeadersToArray(request.headers),
                method: request.method,
            }
        }
    });
  }

  private convertHttpHeadersToArray(headers: HttpHeaders): { key: string; value: string }[] {
      const headersArray: { key: string; value: string }[] = [];
      headers.keys().forEach(key => {
          const value = headers.get(key);
          if (value) {
              headersArray.push({ key, value });
          }
      });
      return headersArray;
  }
}